import styled from "styled-components"

const ErrorMessageContainer = styled.div`
  margin: 100px 10px 200px 10px;
`

const ErrorHeading = styled.h1`
  color: white;
  text-align: center;
`

const ErrorParagraph = styled.p`
  color: white;
  text-align: center;
`

export default function Custom404() {
  return <ErrorMessageContainer>
    <ErrorHeading>404 - Page Not Found</ErrorHeading>
    <ErrorParagraph>
      Sorry, we don&apos;t know what was meant to be here.
    </ErrorParagraph>
    <ErrorParagraph>
      You might like to try going back or using one of the links in the header.
    </ErrorParagraph>
  </ErrorMessageContainer>
}